import translate from "components/translations/translations.wrapper";
import noInvoice from "../../icons/Pictogram-selected-invoice.svg";
import BypassCIRSkeleton from "components/taskdetails-remake/panels/attachments/BypassCIR.Skeleton";

export const AttachmentsPanelInWaiting = (props: any) => {

    return (
        <div className={"d-flex flex-column detached p-3 w-100 h-100"}>
            <div className="d-flex flex-row py-12">
                <div className="d-flex mr-auto font-16 semibold attachment-header__title__div">
                    {props.translate("taskDetail.waitingForDocument.header")}
                </div>
            </div>
            <BypassCIRSkeleton />
            <div className="d-flex flex-column h-100 flex-grow-1 rounded-8 waiting-state p-64">
                <img src={noInvoice} alt={props.translate("taskDetail.waitingForDocument.title")} />
                <div className="pt-32 font-16 semibold">
                    {props.translate("taskDetail.waitingForDocument.title")}</div>
                <div>{props.translate("taskDetail.waitingForDocument.subtitle")}</div>
            </div>
        </div>

    );
};

export default translate(AttachmentsPanelInWaiting);