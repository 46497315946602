import { useMemo, useState } from "react";
import NewWindow from "react-new-window";
import AttachmentsPanel from "components/taskdetails-remake/panels/attachments/AttachmentsPanel.component";
import AttachmentsPanelHeader from "components/taskdetails-remake/panels/attachments/AttachmentsPanelHeader.component";
import {
    getAttachmentsLoadingStatus,
    isDocumentPreviewDetached,
    getTaskAttachments,
    getTaskDetails, isDocumentPreviewWaiting,
} from "components/store/application.reducers";
import { useDispatch, useSelector } from "react-redux";
import { documentPreviewReattach } from "components/taskdetails-remake/taskDetails.action";
import translate from "components/translations/translations.wrapper";
import AttachmentsPanelInWaiting
    from "components/taskdetails-remake/panels/attachments/AttachmentsPanelInWaiting.component";

export function DocumentPreviewWindow(props: any) {
    const dispatch = useDispatch();
    const [popupRef, setPopupRef] = useState(null);
    const attachments = useSelector(getTaskAttachments);
    const loadingStatus = useSelector(getAttachmentsLoadingStatus);
    const taskDetails = useSelector(getTaskDetails);
    const isPreviewDetached = useSelector(isDocumentPreviewDetached);
    const isPreviewWaiting = useSelector(isDocumentPreviewWaiting);
    const width = parseInt(window.localStorage.getItem(`@@grid/attachments-width`)) || 1200;
    const height = document?.body.clientHeight || 800;

    function onOpen(nextPopupRef: any) {
        setPopupRef(nextPopupRef);
        window.onbeforeunload = onUnload;
    }

    function onUnload() {
        dispatch(documentPreviewReattach());
        setPopupRef(null);
        window.onbeforeunload = null;
    }

    const memoizedOptions = useMemo(
        () => ({
            ownerDocument: popupRef ? popupRef.document : undefined,
        }),
        [popupRef],
    );

    const getAttachmentsPanelComponent = () => {
        if (!memoizedOptions.ownerDocument) {
            return <AttachmentsPanelInWaiting />;
        }
        return ( isPreviewWaiting ?
            <AttachmentsPanelInWaiting /> :
            <div className={"d-flex flex-column detached w-100 h-100"}>
                <AttachmentsPanelHeader />
                <AttachmentsPanel attachments={attachments}
                                  loadingStatus={loadingStatus}
                                  taskDetails={taskDetails}
                                  translate={props.translate}
                                  ownerDocument={memoizedOptions}
                />
            </div>
        );
    };


    return (
        <div>{isPreviewDetached && <NewWindow features={{ width: width, height: height }}
                                              title={" Approval"}
                                              onOpen={onOpen}
                                              onUnload={onUnload}>
            {getAttachmentsPanelComponent()}
        </NewWindow>
        }</div>
    );
}

export default translate(DocumentPreviewWindow);