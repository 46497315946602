import React, {Component} from 'react';

import {loadUserData} from '../usercontext/user.action';
import {connect} from 'react-redux';
import UserContext from '../usercontext/UserContext.component';
import * as logger from '../../utils/logger.function';
import ErrorMessagePopup from '../popup/ErrorMessagePopup.component';
import {
    getInitialized,
    getHydrated,
    getErrorMessageVisibility,
} from "../store/application.reducers";
import * as reduxSelectors from "../store/application.reducers";
import {snowplowInit} from "utils/snowplow.js";
import  DocumentPreviewWindow  from "components/taskdetails-remake/DocumentPreviewWindow.component.tsx";

/**
 * container/wrapper component, responsible for proper user context handling
 * ~ check user context on each page refresh, after the store state is hydrated
 * ~ display application after the previous finished
 */

export class AppInitialization extends Component {

    componentDidMount() {
        if (!this.props.showErrorMessage) {
            if (this.props.hydrated && !this.props.initialized) {
                // we need the store in sync with actual session from backend, so emit action after store hydration
                logger.debug("store.state hydrated, dispatching action to load user context");
                this.loadUserData();
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.hydrated !== this.props.hydrated || prevProps.initialized !== this.props.initialized || this.props.showErrorMessage !== prevProps.showErrorMessage) {
            if (!this.props.showErrorMessage) {
                if (this.props.hydrated && !this.props.initialized) {
                    // we need the store in sync with actual session from backend, so emit action after store hydration
                    logger.debug("store.state hydrated, dispatching action to load user context");
                    this.loadUserData();
                }
            }
        }
    }

    loadUserData() {
        this.props.loadUserData().then(() => {
            snowplowInit(this.props.userConfiguration);
        });
    }

    render() {
        /**
         * not sure why, but this has to be here :)
         * in case I've moved that into componentDidMount, then it won't get triggered for refreshing in myTasks
         * if I move it to componentWillReceiveProps, then it won't get triggered for refreshing for example taskDetail
         */
        /*if(!this.props.showErrorMessage) {
            if (this.props.hydrated && !this.props.initialized) {
                // we need the store in sync with actual session from backend, so emit action after store hydration
                logger.debug("store.state hydrated, dispatching action to load user context");
                this.props.loadUserData();
            }
        }*/

        if (!this.props.initialized) {
            return (
                <div className="col-md-12">
                    {this.props.showErrorMessage &&
                    <ErrorMessagePopup error={this.props.showErrorMessage}/>
                    }
                    <span className="appicon icon loading"/>
                </div>
            );
        } else {
            const propsForChildren = Object.assign({}, this.props);
            delete propsForChildren.initialized;
            delete propsForChildren.hydrated;

            return (
                <div>
                    {this.props.showErrorMessage &&
                    <ErrorMessagePopup error={this.props.showErrorMessage}/>
                    }
                    <UserContext {...propsForChildren} />
                    <DocumentPreviewWindow/>
                </div>
            );
        }
    }
}


const mapStateToProps = function (store) {
    return {
        // local : store
        initialized: getInitialized(store),
        hydrated: getHydrated(store),
        showErrorMessage: getErrorMessageVisibility(store),
        userConfiguration: reduxSelectors.getUsersData(store)
    };
};


export default connect(mapStateToProps, {
    loadUserData
})(AppInitialization)