import React from 'react';
import {Form} from 'react-bootstrap';
import {connect} from 'react-redux';
import translate from '../translations/translations.wrapper.jsx';
import {
    getUsersSettings,
    getUsersLoggedInData,
    getLanguage,
    getUsersCurrentContextId
} from '../store/application.reducers';
import {loadUserData} from '../usercontext/user.action';
import UserSearch from '../input/UserSearch.component'
import * as Api from 'utils/api/api';
import SettingsButtonsBarView from './SettingsButtonsBarView.component';
import SettingsWrapper from "./SettingsWrapper.component";
import moment from 'moment';
import DateFilter from '../input/CalendarInput.component';
import {handleError, insufficientRightsError} from 'utils/errorHandle.function';
import {userHasAccessRights} from "utils/accessRights.function";
import {canViewConfiguration} from "../menu/Menu.component";
import * as reduxSelectors from '../store/application.reducers';
import {autoMenuStyle} from "utils/reactSelectTheme";

const RADIO_SELECTION = {
    inactive: 1,
    activeNow: 2,
    activePeriod: 3,
};
const TO = "to";
const FROM = "from";
let canSave = true;

export class SubstituteSettings extends SettingsWrapper {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                label: '',
                value: undefined
            },
            activePeriod: false,
            active: false,
            activeNow: false,
            from: '',
            to: '',
            message: '',
            okEnabled: false,
            cancelEnabled: false,
            currentContext: props.context
        };

        this.getData = this.getData.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.changeActive = this.changeActive.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.isOkEnabled = this.isOkEnabled.bind(this);
        this.getToday = this.getToday.bind(this);
        this.calendarBlur = this.calendarBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        if (!userHasAccessRights(this.props.userData.userRoles) && canViewConfiguration(this.props.userData.userRoles)) {
            handleError(insufficientRightsError);
            this.props.history.replace('/');
        }
    }

    componentDidUpdate(prevProps) {
       if (prevProps.userSettings.substitute !== this.props.userSettings.substitute) {
            this.getData();
        }
    }


    getData() {
        let substitute = Object.assign({}, this.props.userSettings.substitute);
        canSave = true;
        if (substitute) {
            this.setState({
                user: {
                    label: substitute.substituteName,
                    value: substitute.substituteId
                },
                active: !substitute.active && substitute.substituteId,
                message: substitute.substituteMessage ? substitute.substituteMessage.message : '',
                activeNow: substitute.active && !substitute.to,
                activePeriod: substitute.active && substitute.to && substitute.from,
                from: substitute.from && substitute.to ? substitute.from : '',
                to: substitute.to ? substitute.to : '',
                okEnabled: false,
                cancelEnabled: false
            });
        }
        this.props.blocker.unblockNavigation();
    }

    getToday() {
        return new Date().toISOString().substring(0, 10);
    }

    calendarBlur(event) {
        let fromDate = new Date(this.refs.from.getFormattedValue());
        let toDate = new Date(this.refs.to.getFormattedValue());
        if (isNaN(fromDate.valueOf())) {
            fromDate = Object.assign(this.state.from);
        }
        if (isNaN(toDate.valueOf())) {
            toDate = Object.assign(this.state.to);
        }
        this.setState({
            from: fromDate,
            to: toDate
        });
    }

    save() {
        let self = this;
        if (canSave) {
            canSave = false;
            this.setState({
                okEnabled: false
            });
            let newData = Object.assign({}, self.props.userSettings);
            let substitute = {};
            //send active = false if no substitute id
            substitute.active = self.state.user.value ? !self.state.active : false;
            substitute.substituteId = self.state.user.value || 0;
            substitute.substituteName = self.state.user.label;

            if (this.state.activePeriod && self.refs.to && self.refs.from) {
                let getToValue = moment(this.state.to).hours(12);
                let getFromValue = moment(this.state.from).hours(12);

                let toValue = self.refs.to && self.refs.to.getValue() && this.state.activePeriod ? new Date(getToValue).toISOString().slice(0, 10) : null;
                let fromValue = self.refs.from && self.refs.from.getValue() && this.state.activePeriod ? new Date(getFromValue).toISOString().slice(0, 10) : null;

                substitute.from = fromValue;
                substitute.to = toValue;
            } else if (this.state.activeNow) {
                substitute.from = this.getToday();
                substitute.to = null;
            }

            newData.substitute = Object.assign({}, self.props.userSettings.substitute, substitute);
            Api.updateUserSettings(newData).then(response => {
                canSave = true;
                this.props.loadUserData();
            }, error => {
                canSave = false;
                handleError(error);
                this.props.loadUserData();
                this.setState({
                    okEnabled: false,
                    cancelEnabled: true,
                    message: undefined
                });
            });
        }
    }

    cancel() {
        this.props.loadUserData();
    }

    isOkEnabled(newState) {
        let current = Object.assign({}, this.props.userSettings.substitute);
        let to = current.to ? current.to : '';
        let from = current.from ? current.from : '';
        const value=this.state.user.value !== current.substituteId || (!newState.active !== current.active || !newState.activeNow !== current.active || newState.to !== to || newState.from !== from);
       this.props.blocker.shouldWaitForConfirmation(value);
        return value;
    }

    changeUser(value) {
        let newState = {
            user: {
                label: value?.label || null,
                value: value?.value || null
            }
        }
        if (this.state.user.label !== newState.user.label) {
            newState.okEnabled = true;
            newState.cancelEnabled = true;
            newState.active = false;
            newState.activeNow = !!newState.user.label;
            newState.activePeriod = false;
            this.setState({...newState});
            this.props.blocker.shouldWaitForConfirmation(true);
        }
    }

    changeActive(event, type) {
        let newState = Object.assign({}, this.state);
        switch (type) {
            case RADIO_SELECTION.inactive:
                newState.active = event.target.checked;
                newState.activeNow = false;
                if (this.state.activePeriod) {
                    newState.to = newState.from = '';
                }
                newState.activePeriod = false;
                break;

            case RADIO_SELECTION.activeNow:
                newState.activeNow = event.target.checked;
                newState.active = false;
                if (this.state.activePeriod) {
                    newState.to = newState.from = '';
                }
                newState.activePeriod = false;
                break;
            case RADIO_SELECTION.activePeriod:
                newState.activePeriod = event.target.checked;
                newState.to = this.props.userSettings.substitute.to ? this.props.userSettings.substitute.to : this.getToday();
                newState.from = this.props.userSettings.substitute.from ? this.props.userSettings.substitute.from : this.getToday();
                newState.activeNow = newState.active = false;
                break;
            default:
                break;
        }
        newState.okEnabled = true;
        newState.cancelEnabled = true;
        this.setState({...newState});
        this.props.blocker.shouldWaitForConfirmation(true);
    }

    changeDate(val, type) {
        const fromDate = type === FROM ? new Date(val) : new Date(this.refs.from.getValue());
        const toDate = type === TO ? new Date(val) : new Date(this.refs.to.getValue());
        const datedValue = new Date(val);
        let dateValue = datedValue instanceof Date && !isNaN(datedValue.valueOf()) ? val : this.getToday();
        let newState = Object.assign({}, this.state);

        if (fromDate.getTime() > toDate.getTime()) {
            if (type === TO) {
                newState.from = dateValue;
            } else {
                newState.to = dateValue;
            }
        }

        newState[type] = dateValue;
        newState.okEnabled = true;
        newState.cancelEnabled = true;
        this.setState({...newState});
        this.props.blocker.shouldWaitForConfirmation(true);
    }

    buildContent() {
        //new Date of string will make the hour 00:00:00 this date converted to ISOString() will
        //substract one day from the date and cause the calendar to go backwards
        let toDateWithTime = new Date(this.state.to).setHours(12);
        let fromDateWithTime = new Date(this.state.from).setHours(12);

        //let tovalue = this.state.to ? new Date(toDateWithTime).toLocaleDateString("en-GB") : TODAY;
        //let fromvalue = this.state.from ? new Date(fromDateWithTime).toLocaleDateString("en-GB") : TODAY;

        let lang = moment().locale(this.props.userLanguage === "en" ? "en-gb" : this.props.userLanguage);
        let valueFormatter = lang.localeData()._longDateFormat.L;

        return (
            <article className="col-md-12 px-0" aria-labelledby="substituteWillApproveTxt">
                <div className="col-lg-8 col-md-10 panel panel-default settings-panel px-0">
                    <div className={"panel-heading"} id="substituteWillApproveTxt">
                        <div className="panel-title">
                            {this.props.translate("settings.substitute.willApprove")}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="col-md-12 px-0 mb-3">
                            <div className="col-md-12 px-0">
                                <UserSearch className="col-md-12" ref="recipientInput"
                                            propagateValue={this.changeUser}
                                            value={{label: this.state.user.label, value: this.state.user.value}}
                                            placeholder={this.props.translate("settings.substitute.selectUser")}
                                            clearable={true}
                                            styles={autoMenuStyle}
                                            ariaLabel={this.state.user.label ? this.state.user.label : this.props.translate("settings.substitute.selectUser")}
                                            translate={this.props.translate}/>
                            </div>
                        </div>
                        <div className="col-md-12 px-0">
                            <div className={"w-100"}>
                                <Form.Check name="active" type="radio" disabled={!this.state.user.value}
                                            checked={this.state.active}
                                            value={this.state.inactive}
                                            id="setSubstitute"
                                            className={"radio " + (this.state.user.value ? '' : 'disabled')}
                                            onChange={(e) => this.changeActive(e, RADIO_SELECTION.inactive)}
                                            label={this.props.translate("settings.substitute.inactive")}/>
                            </div>
                            <div className={"w-100"}>
                                <Form.Check name="activeNow" type="radio" disabled={!this.state.user.value}
                                            checked={this.state.activeNow}
                                            value={this.state.activeNow}
                                            id="activeNow"
                                            className={"radio " + (this.state.user.value ? '' : 'disabled')}
                                            onChange={(e) => {
                                                this.changeActive(e, RADIO_SELECTION.activeNow)
                                            }}
                                            label={this.props.translate("settings.substitute.activeNow")}/>
                            </div>
                            <div className={"w-100"}>
                                <Form.Check name="activePeriod" type="radio" disabled={!this.state.user.value}
                                            ref="activePeriod"
                                            value={this.state.activePeriod !== null ? this.state.activePeriod : undefined}
                                            checked={this.state.activePeriod}
                                            id="activePeriod"
                                            className={"radio " + (this.state.user.value ? '' : 'disabled')}
                                            onChange={(e) => {
                                                this.changeActive(e, RADIO_SELECTION.activePeriod)
                                            }}
                                            label={this.props.translate("settings.substitute.activePeriod")}/>
                            </div>

                            {this.state.activePeriod &&
                                <fieldset className="row mt-3">
                                    <div className="col-md-6 row d-flex align-items-center" aria-labelledby="substituteFromDate">
                                    <span className="col-md-2" id="substituteFromDate">
                                        {this.props.translate("settings.substitute.from")}
                                    </span>
                                        <div className="col-md-7 px-0">
                                            <DateFilter
                                                ref="from"
                                                translate={this.props.translate}
                                                userLanguage={this.props.userLanguage}
                                                onBlur={this.calendarBlur}
                                                format={valueFormatter}
                                                minDate={moment()}
                                                selectedValue={fromDateWithTime}
                                                onChange={(value) => this.changeDate(value, FROM)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 row d-flex align-items-center" aria-labelledby="substituteToDate">
                                    <span className="col-md-2 ps-0 pe-3 text-end" id="substituteToDate">
                                        {this.props.translate("settings.substitute.to")}
                                     </span>
                                        <div className="col-md-7 px-0">
                                            <DateFilter
                                                ref="to"
                                                userLanguage={this.props.userLanguage}
                                                translate={this.props.translate}
                                                format={valueFormatter}
                                                onBlur={this.calendarBlur}
                                                minDate={moment()}
                                                selectedValue={toDateWithTime}
                                                onChange={(value) => this.changeDate(value, TO)}
                                            />
                                        </div>

                                    </div>
                                </fieldset>
                            }

                            <SettingsButtonsBarView okCallback={this.save} okEnabled={this.state.okEnabled}
                                                    cancelEnabled={this.state.cancelEnabled}
                                                    cancelCallback={this.cancel}/>
                        </div>
                    </div>
                </div>

                {this.state.message &&
                    <div className="col-md-5">
                        <div className="alert alert-warning" role="alert">
                            {this.state.message}
                        </div>
                    </div>
                }
            </article>
        );
    }
}

const withTranslations = translate(SubstituteSettings);

const mapStateToProps = function (store) {
    return {
        userSettings: getUsersSettings(store),
        user: getUsersLoggedInData(store),
        userLanguage: getLanguage(store),
        context: getUsersCurrentContextId(store),
        userData: reduxSelectors.getUsersData(store)
    };
};
const connected = connect(mapStateToProps, {loadUserData})(withTranslations);
export default connected;