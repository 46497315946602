import React from "react";
import translate from "components/translations/translations.wrapper";
import MissingFile from "components/taskdetails-remake/icons/missing-file.svg";

interface UnavailableProps {
    translate: React.FC;
    callback: () => void;
}

//this attachemnt has been deleted and we cannot reach it
export const Unavailable = ({translate, callback}: UnavailableProps): React.ReactElement => {

    return <div className={"unavailable-attachment bg-natural-white py-2 rounded-8"}>
        <img src={MissingFile} alt={"missing file"} className={"float-left w-25"}/>
        <strong>{translate("taskDetail.panels.attachments.unavailableTitle")}</strong>
        <br/>
        <span>{translate("taskDetail.panels.attachments.unavailableDetails")}</span>
        <br/>
        <span>
            <button className={"btn btn-primary btn-lg"} onClick={callback}>
                <span className={"vismaicon vismaicon-dynamic vismaicon-replace"}/>
                {translate("taskDetail.panels.attachments.tryAgain")}
            </button>
        </span>
    </div>
}

export default translate(Unavailable);