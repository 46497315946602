import hideDocumentDetails from "../icons/document-details.svg";
import hideWorkflowDetails from "../icons/workflow-details.svg";
import hideComments from "../icons/comments.svg";
import hideDocumentPreview from "../icons/document-preview-hide.svg";
import reattachDocumentPreview from "../icons/document-reatach.svg";
import showDocumentDetails from "../icons/document-details-grey.svg";
import showWorkflowDetails from "../icons/workflow-details-grey.svg";
import showComments from "../icons/comments-grey.svg";
import showDocumentPreview from "../icons/document-preview-show.svg";
import showDocumentPreviewDisabled from "../icons/document-preview-disabled.svg";
import detachDocumentPreview from "../icons/document-detach.svg";
import detachDocumentPreviewDisabled from "../icons/document-detach-disabled.svg";

import { COMMENTS, DOCUMENT_DETAILS, DOCUMENT_PREVIEW, WORKFLOW_DETAILS } from "utils/constants.js";
import { WarningIcon } from "../panels/documentdata/WarningsTooltip.component";
import { TaskSidebarButtonProps, TaskSidebarProps } from "types/taskSidebar";
import { useDispatch, useSelector } from "react-redux";
import { documentPreviewDetach, documentPreviewReattach } from "components/taskdetails-remake/taskDetails.action";
import { isDocumentPreviewDetached } from "components/store/application.reducers";


export function TaskSidebar(props: TaskSidebarProps) {

    const documentHasWarnings = props.documentData?.warnings?.length > 0;
    const { panelAvailability, panelVisibility, translate } = props;
    const dispatch = useDispatch();
    const isPreviewDetached = useSelector(isDocumentPreviewDetached);

    const getDocumentPreviewImg = () => {
        if (!hideDocumentPreviewAllowed) {
            return showDocumentPreviewDisabled;
        }
        if (props.panelVisibility[DOCUMENT_PREVIEW]) {
            return hideDocumentPreview;
        } else {
            return showDocumentPreview;
        }
    };

    const getDetachDocumentPreviewImg = () => {
        if (isPreviewDetached) {
            return reattachDocumentPreview;
        } else {
            if (!props.panelVisibility[DOCUMENT_PREVIEW]) {
                return detachDocumentPreviewDisabled;
            } else {
                return detachDocumentPreview;
            }
        }
    };

    const detachPreview =() => {
        isPreviewDetached ?
            dispatch(documentPreviewReattach()) :
            dispatch(documentPreviewDetach());
    }

    const hideDocumentPreviewAllowed = !isPreviewDetached && panelAvailability[DOCUMENT_PREVIEW];
    const detachDocumentPreviewAllowed = isPreviewDetached || (!isPreviewDetached && panelVisibility[DOCUMENT_PREVIEW]);

    return (
        <div className="d-flex flex-column task-sidebar">
            <div className="d-flex flex-column fixed-buttons">
                <TaskSidebarButton panelType={DOCUMENT_DETAILS}
                                   hideTitle={"taskDetail.panelButtons.hideDocumentDetails"}
                                   showTitle={"taskDetail.panelButtons.showDocumentDetails"}
                                   alt={"taskDetail.panelButtons.documentDetails"}
                                   img={panelVisibility[DOCUMENT_DETAILS] ? hideDocumentDetails : showDocumentDetails}
                                   showWarning={documentHasWarnings}
                                   actionAllowed={panelAvailability[DOCUMENT_DETAILS]}
                                   panelVisibility={panelVisibility}
                                   togglePanelVisibility={props.togglePanelVisibility}
                                   translate={translate} />
                <TaskSidebarButton panelType={WORKFLOW_DETAILS}
                                   hideTitle={"taskDetail.panelButtons.hideWorkflowDetails"}
                                   showTitle={"taskDetail.panelButtons.showWorkflowDetails"}
                                   alt={"taskDetail.panelButtons.workflowDetails"}
                                   img={panelVisibility[WORKFLOW_DETAILS] ? hideWorkflowDetails : showWorkflowDetails}
                                   actionAllowed={panelAvailability[WORKFLOW_DETAILS]}
                                   panelVisibility={panelVisibility}
                                   togglePanelVisibility={props.togglePanelVisibility}
                                   translate={translate} />
                {props.isCommentsVisible &&
                    <TaskSidebarButton panelType={COMMENTS}
                                       hideTitle={"taskDetail.panelButtons.hideComments"}
                                       showTitle={"taskDetail.panelButtons.showComments"}
                                       alt={"taskDetail.panelButtons.comments"}
                                       img={panelVisibility[COMMENTS] ? hideComments : showComments}
                                       actionAllowed={panelAvailability[COMMENTS]}
                                       panelVisibility={panelVisibility}
                                       togglePanelVisibility={props.togglePanelVisibility}
                                       translate={translate}
                                       showCommentsCount={!panelVisibility[COMMENTS] && props.commentsCount > 0}
                                       commentsCount={props.commentsCount} />
                }
                <div className="divider"></div>
                <TaskSidebarButton panelType={DOCUMENT_PREVIEW}
                                   hideTitle={"taskDetail.panelButtons.hideDocumentPreview"}
                                   showTitle={"taskDetail.panelButtons.showDocumentPreview"}
                                   alt={"taskDetail.panelButtons.documentPreview"}
                                   img={getDocumentPreviewImg()}
                                   actionAllowed={hideDocumentPreviewAllowed}
                                   panelVisibility={panelVisibility}
                                   togglePanelVisibility={props.togglePanelVisibility}
                                   translate={translate} />
                <button className={`action_btn panel-button${detachDocumentPreviewAllowed ? "" : " disabled"}`}
                        title={isPreviewDetached ? translate("taskDetail.panelButtons.reatachDocumentPreview") : translate("taskDetail.panelButtons.detachDocumentPreview")}
                        onClick={detachPreview} type={"button"}
                        data-cy="taskDetail.panelButtons.detachDocumentPreview">
                    <img src={getDetachDocumentPreviewImg()}
                         alt={translate("taskDetail.panelButtons.detachDocumentPreview")} />
                    {isPreviewDetached && <div className="emphasis"></div>}
                </button>
                <div className="divider"></div>
            </div>
        </div>
    );
}

export function TaskSidebarButton(props: TaskSidebarButtonProps) {
    return (
        <button className={`action_btn panel-button${props.actionAllowed ? "" : " disabled"}`}
                title={props.panelVisibility[props.panelType] ? props.translate(props.hideTitle) : props.translate(props.showTitle)}
                onClick={() => props.togglePanelVisibility(props.panelType)} type={"button"}
                data-cy={props.alt}>
            <img src={props.img}
                 alt={props.translate(props.alt)} />
            {props.showWarning && !props.panelVisibility[props.panelType] ? <WarningIcon /> : null}
            {props.showCommentsCount &&
                <span className="badge badge-danger panel-button-badge"
                      data-cy="taskDetail.panelButtons.comments.number">
                                    {props.commentsCount <= 99 ? props.commentsCount : "99+"}
                                </span>}
            {props.panelVisibility[props.panelType] && props.actionAllowed && <div className="emphasis"></div>}
        </button>
    );
}