import React from 'react';
import translate from '../translations/translations.wrapper.jsx';
import managerIcon from "./icons/manager_app.svg";

const SmallScreenAlert = (props) => {

    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    const isIOS = !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g);

    return (
        <div id="min-rezolution-message">
            <div className="alert alert-danger">
                <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error"/>
                <div>{props.translate("smallScreen.title")}</div>
                <br/>
                <div>
                    {props.translate("smallScreen.message")}
                    <br/>
                    {props.translate("smallScreen.appManager")}
                </div>
            </div>
            {(isAndroid || isIOS) &&
                <div className={"border border-2 rounded-8 w-100 fixed-top bg-default px-24 py-24"}>
                    <a href={isIOS ? ENVIRONMENT.IOS_APP_STORE_LINK : ENVIRONMENT.ANDROID_APP_STORE_LINK} target={"_blank"}
                       className={"text-decoration-none"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div className={"d-flex align-items-center"}>
                                <img src={managerIcon} alt={"Visma Manager App"} className={"icon-48 me-4"}/>
                                <div>{props.translate("smallScreen.downloadManager")}</div>
                            </div>
                            <div className={"border-2 border-start ps-4"}>
                                <span className={"vismaicon vismaicon-lg vismaicon-download"}/>
                            </div>
                        </div>
                    </a>
                </div>
            }
        </div>
    )
}
const withTranslations = translate(SmallScreenAlert);
export default withTranslations;