import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import "./styles/skeletonLoading.scss";
import * as styles from "./styles/variables.module.scss";
import { isDocumentPreviewDetached } from "components/store/application.reducers";
import { useSelector } from "react-redux";


const {skeletonBackgroundColor, skeletonButtonColor, skeletonHighlightColor} = styles;

export const BypassCIRSkeleton = () => {
    const isPreviewDetached = useSelector(isDocumentPreviewDetached);

    return <div className="grid-document-preview-panel-loading skeleton position-relative">
        <SkeletonTheme baseColor={skeletonBackgroundColor} highlightColor={skeletonHighlightColor}>
            {!isPreviewDetached && <div className="d-flex justify-content-between align-items-center mb-3 w-100">
                <Skeleton className="title-loading-skeleton my-3" baseColor={skeletonButtonColor} />
                <Skeleton className="button-loading-skeleton ms-4"
                          containerClassName={"min-max-btn-group buttons-bar-skeleton d-flex me-4 my-3"}
                          baseColor={skeletonButtonColor} count={2} />
            </div>
            }
            <div className="buttons-bar-skeleton-dark d-flex justify-content-between align-items-center w-100">
                <Skeleton className="button-loading-skeleton ms-4"
                          containerClassName={"d-flex align-items-center"}
                          baseColor={skeletonButtonColor} count={3} />

                <div className="d-flex align-items-center">
                    <Skeleton className="button-loading-skeleton me-4" baseColor={skeletonButtonColor} />
                    <Skeleton className="range-loading-skeleton me-4" baseColor={skeletonButtonColor} />
                    <Skeleton className="button-loading-skeleton" baseColor={skeletonButtonColor} />
                </div>

                <Skeleton className="button-loading-skeleton me-4"
                          containerClassName={"d-flex align-items-center"}
                          baseColor={skeletonButtonColor} count={4} />
            </div>
        </SkeletonTheme>
    </div>
}

export default BypassCIRSkeleton;