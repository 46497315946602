import React, {Fragment} from 'react';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import translate from '../translations/translations.wrapper.jsx';
import ConfigurationWrapper from '../configuration/ConfigurationWrapper.component';
import SettingsButtonsBarView from '../settings/SettingsButtonsBarView.component';
import NumericInput from 'react-numeric-input';
import * as Api from 'utils/api/api';
import * as _ from "lodash";
import {DOCUMENT_TYPE} from 'utils/constants';
import "./dueSettings.scss";
import {userHasAccessRights} from "utils/accessRights.function";
import {handleError, insufficientRightsError} from "utils/errorHandle.function";
import {canViewConfiguration} from "../menu/Menu.component";

const TASK_DUE_PERIOD = "taskDuePeriod";
const DOC_DUE = "documentDueWarningPeriod";
const MONTH_DAY = "documentDueDayOfMonth";
const PERIOD_DUE = "documentDuePeriod";
const USE_DAY_OF_MONTH = "useDayOfMonth";

export class DueSettings extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            initialSettings: [],
            settings: [],
            okEnabled: false
        };
        this.cancel = this.cancel.bind(this);
        this.getData = this.getData.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        this.save = this.save.bind(this);

    }

    componentDidMount(props) {
        if (!userHasAccessRights(this.props.userData.userRoles, this.props.location) || !canViewConfiguration(this.props.userData.userRoles)) {
            handleError(insufficientRightsError);
        }

        super.componentDidMount(props);
    }

    cancel() {
        this.getData();
        this.props.blocker.unblockNavigation();
    }

    getData() {
        Api.getDocumentTypesConfiguration().then(settings => {
            this.setState({
                settings: settings.result,
                initialSettings: settings.result,
                okEnabled: false,
            });
        });
    }

    updateSettings(value, docType, prop) {
        let enableOk = false;
        let oldState = _.cloneDeep(this.state.settings);
        let docIndex = _.findIndex(oldState, value => {
            return value.documentType === docType
        });
        if (docIndex !== -1) {
            let validValue = value;
            oldState[docIndex][prop] = validValue;
            enableOk = this.state.initialSettings[docIndex][prop] !== value;
        }

        this.setState({
            okEnabled: enableOk,
            settings: oldState
        });
        this.props.blocker.shouldWaitForConfirmation(enableOk);
    }

    save() {
        Api.postDocumentTypesConfiguration(this.state.settings).then(response => {
            this.setState({
                okEnabled: false
            });
        });
        this.props.blocker.unblockNavigation();
    }


    buildContent() {

        let documentsConfigurations = this.state.settings.map((config, index) => {
            return (<div className="doctypes-column" key={index}>
                <div className="doc-types cropped-text text-end"
                     title={this.props.translate("documentTypes." + DOCUMENT_TYPE.asString(config.documentType))}>
                    {this.props.translate("documentTypes." + DOCUMENT_TYPE.asString(config.documentType))}
                </div>
                <OverdueSettings key={index} config={config} updateSettings={this.updateSettings}
                                 toggleUseDayOfMonth={this.toggleUseDayOfMonth}
                                 lockedEdit={!this.props.userRoles.systemAdministrator}/>
            </div>)
        });

        return (
            <Fragment>
                <div className="row mx-0" id="due_settings">
                    <OverdueInfo translate={this.props.translate}/>

                    <div className="col-md-9 combos-list ps-0">
                        <div className="values-panel">
                            {documentsConfigurations}
                        </div>
                    </div>
                </div>
                <SettingsButtonsBarView okCallback={this.save} cancelEnabled={this.state.okEnabled}
                                        okEnabled={this.state.okEnabled}
                                        cancelCallback={this.cancel}/>
            </Fragment>

        )
    }
}

const withTranslations = translate(DueSettings);
const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        userData: reduxSelectors.getUsersData(store)
    };
};

const connected = connect(mapStateToProps, {})(withTranslations);
export default connected;

const OverdueInfo = (props) => {
    let {translate} = props;
    return (
        <Fragment>
            <h3 className="d-flex align-items-center py-0 my-0">{translate("dueSettings.header")}
                <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                      title={translate("dueSettings.headerInfo")}/></h3>

            <div className="col-md-3 left-panel pe-0">
                <div className="empty-div"/>
                <div>{translate("dueSettings.daysToComplete")}</div>
                <div>{translate("dueSettings.warningDays")}</div>
                <hr className="my-5"/>
                <div>{translate("dueSettings.documentOverdue")}</div>
                <div>{translate("dueSettings.monthDeadline")}</div>
                <div>{translate("dueSettings.daysDeadline")}</div>
            </div>
        </Fragment>
    )
};

const OverdueSettings = (props) => {
    let {config, lockedEdit} = props;

    return (
        <div>
           <div className="row">
               <div className="col-md-12 no-border text-end float-end">
                   <NumericInput min={1} max={99} value={config.taskDuePeriod} size={1}
                                 className="text-end vertical-center"
                                 disabled={lockedEdit}
                                 strict={true}
                                 onChange={(val) => props.updateSettings(val, config.documentType, TASK_DUE_PERIOD)}/>
               </div>
           </div>
            <div className="row">
                <div className="col-md-12 no-border text-end float-end">
                    <NumericInput min={1} max={99} value={config.documentDueWarningPeriod} size={1}
                                  className="text-end vertical-top"
                                  disabled={lockedEdit}
                                  strict={true}
                                  onChange={(val) => props.updateSettings(val, config.documentType, DOC_DUE)}/>
                </div>
            </div>

            <hr className="my-5"/>

            <div className="row">
                <div className="col-md-8 offset-md-4 mt-5">
                    <div className="top-group group-highlight">
                        <div className="radio">
                            <input type="radio"
                                   id={`monthly-deadline-date-${config.documentType}`}
                                   disabled={lockedEdit}
                                   checked={config.useDayOfMonth}
                                   onChange={(e) => props.updateSettings(e.target.checked, config.documentType, USE_DAY_OF_MONTH)}/>
                            <label htmlFor={`monthly-deadline-date-${config.documentType}`}>
                                <NumericInput min={1} max={31} value={config.documentDueDayOfMonth} size={1}
                                              className="ms-3 text-end vertical-center"
                                              strict={true}
                                              disabled={lockedEdit || !config.useDayOfMonth}
                                              onChange={(val) => props.updateSettings(val, config.documentType, MONTH_DAY)}/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-4">
                    <div className="bottom-group group-highlight">
                        <div className="radio">
                            <input type="radio"
                                   id={`days-after-receipt-${config.documentType}`}
                                   disabled={lockedEdit}
                                   checked={!config.useDayOfMonth}
                                   onChange={(e) => props.updateSettings(!e.target.checked, config.documentType, USE_DAY_OF_MONTH)}/>
                            <label htmlFor={`days-after-receipt-${config.documentType}`}>
                                <NumericInput min={1} max={99} value={config.documentDuePeriod} size={1}
                                              className="ms-3 text-end vertical-top"
                                              strict={true}
                                              disabled={lockedEdit || config.useDayOfMonth}
                                              onChange={(val) => props.updateSettings(val, config.documentType, PERIOD_DUE)}/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};